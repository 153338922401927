<template>
  <div style="font-size: 30px;font-weight: 700;">
    <div style="margin-bottom: 20px;" :style="{ color: color }">
      如何{{ text }}血压计
    </div>
    <div v-if="text == '领取'" class="auto">
      <div>第一步: 注册小程序 联系医院护士获取血压计领取码</div>
      <img src="@/assets/source/image - 副本 3.png" height="100%" alt="" />
      <div>第二步: 点击小程序首页 "领取血压计" 按钮查看领取码</div>
      <img src="@/assets/source/image - 副本 4.png" height="100%" alt="" />
      <div>第三步: 在血压计柜中输入领取码, 领取血压计</div>
      <img src="@/assets/source/image - 副本 5.png" height="100%" alt="" />
    </div>
    <div v-if="text == '归还'" class="auto">
      <div>第一步: 点击小程序首页 "归还血压计" 按钮查看归还码</div>
      <img src="@/assets/source/image - 副本 4.png" height="100%" alt="" />
      <div>第二步: 在血压计柜中点击归还, 输入归还码, 归还血压计</div>
      <img src="@/assets/source/image.png" height="100%" alt="" />
      <div>第三步: 柜门弹开, 将血压计放入其中, 关闭柜门即可</div>
      <img src="@/assets/source/gui.png" height="100%" alt="" />
    </div>
    <div :style="{ background: color }" class="btn" @click="$emit('close')">
      返回
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: ['color', 'text'],
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
<style lang="less" scoped>
.auto {
  max-height: 60%;
  overflow-y: auto;
  text-align: left;
  img {
    text-align: center;
    margin: 5% auto;
    display: flex !important;
    align-items: center !important;
  }
}
.btn {
  text-align: center;
  margin: 0 auto;
  width: 200px;
  height: 70px;
  line-height: 70px;
  position: absolute;
  bottom: 6px;
  left: calc(50% - 100px);
  color: #fff;
  font-size: 30px;
  border-radius: 10px;
}
</style>
