<template>
  <div class="">
    <a-modal
      :dialog-style="{ top: '32%' }"
      :visible="modalVisible"
      :maskClosable="false"
      :footer="null"
      :mask="false"
      width="90%"
      @ok="() => setModal1Visible(false)"
      @cancel="cancel()"
    >
      <div
        v-if="isText"
        style="font-size: 30px;padding: 20px;font-weight: 700;"
        class="title"
      >
        <div :style="{ color: color }" class="" v-if="text == '领取'">
          开门成功
        </div>
        <div :style="{ color: color }" class="" v-else>关门成功</div>
        <img
          v-if="text == '领取'"
          style="margin:20px 0 40px 0;"
          src="@/assets/icon/成功  绿.png"
          alt=""
        />
        <img
          v-else
          src="@/assets/icon/成功  橙.png"
          style="margin:20px 0 40px 0;"
          alt=""
        />
        <div style="text-align: center;">
          <div v-if="text == '领取'">
            请拿<span :style="{ color: color }">{{ boxNumber }}号柜</span
            >取柜中血压计,并关门柜门,谢谢
          </div>
          <div v-if="text == '归还'">
            请将血压计放入<span :style="{ color: color }"
              >{{ boxNumber }}号柜</span
            >中,并关门柜门,谢谢
          </div>
          <div class="btn" @click="modalVisible = false, $emit('closemodalVisible')">关闭</div>
          <div style="font-size:20px;margin:20px">{{ time }}s后自动关闭</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: ['text', 'color', 'state', 'boxNumber'],
  data() {
    return {
      modalVisible: false,
      time: 5,
      state: '',
      name: '',
      isText: true,
    }
  },
  watch: {
    state(val) {
      if (val === 1) {
        this.modalVisible = true
        this.time = 5
        this.timeDate()
      }
      if (val === 0) {
        this.modalVisible = false
      }
    },
  },
  mounted() {},
  methods: {
    timeDate() {
      var countime = setInterval(() => {
        this.time -= 1
        if (this.time < 1) {
          this.modalVisible = false
          this.$emit('closemodalVisible')
          clearInterval(countime)
        }
      }, 1000)
    },
    cancel() {
      this.modalVisible = false
      this.$emit('closemodalVisible')
    },
  },
}
</script>
<style lang="less" scoped>
.btn {
  margin: 40px 0px 0px 0;
  padding: 10px 90px;
  display: inline-block;
  border-radius: 10px;
  color: #fff;

  background: #00a6fa;
}
.title {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
</style>
