<template>
  <div class="header" @mousemove="moveEvent" @click="moveEvent">
    <div class="flex">
      <div class="logo">
        <div
          style="width: 100px;margin-right: 20px; height: 100px;padding:10px 8px 8px 10px;background-color: #fff;border-radius: 50%;"
        >
          <img src="@/assets/logo.png" width="100%" height="100%" alt="" />
        </div>
        <div class="text">
          <div class="title">{{ arkDetailData.name || '优心助理自助柜' }}</div>
          <!-- <div class="sub"></div> -->
        </div>
      </div>
      <div class="set-up" @click="getSqlCheck"></div>
    </div>
    <div class="box">
      <div
        class="card"
        @click="() => setModal1Visible(true, 1)"
        style="margin-right: 4%;"
      >
        <div class="icon">
          <div>
            <img src="~@/assets/icon/领取  白.png" height="90px" alt="" />
          </div>
          <div class="text">领取</div>
        </div>
        <div style="margin: 50px 0 0 0;">领取血压计</div>
      </div>
      <div
        class="card"
        @click="() => setModal1Visible(true, 2)"
        style="background-color: #00a6fa;"
      >
        <div class="icon">
          <div>
            <img src="~@/assets/icon/归还  白.png" height="90px" alt="" />
          </div>
          <div class="text">归还</div>
        </div>
        <div style="margin: 50px 0 0 0;">归还血压计</div>
      </div>
    </div>
    <a-modal
      :dialog-style="{ top: '22%' }"
      :visible="modal1Visible"
      :maskClosable="false"
      :footer="null"
      :mask="false"
      width="90%"
      @ok="() => setModal1Visible(false)"
      @cancel="cancel()"
    >
      <div class="model-title" v-if="!isNotes">
        <div class="model-flex" :style="{ color: color }">
          <span>{{ text1 }}</span>
          <span class="text" @click="isNotes = !isNotes" v-if="notes">
            如何获得动态码
            <a-icon type="question-circle" theme="filled"
          /></span>
        </div>
      </div>
      <div style="padding: 30px;">
        <div v-if="isNotes" style="height: 717px;overflow-y: auto;">
          <course :color="color" :text="text" @close="close" />
        </div>
        <div v-else>
          <keyboard
            v-if="iskeyboard"
            @keyboard-input="keyboardInput"
            @trigger="trigger"
            ref="keyboard"
            @cancel="cancel()"
            :text="text"
            @moveEvent="moveEvent"
            :color="color"
          />
        </div>
      </div>
    </a-modal>
    <tips
      :color="color"
      :text="text"
      :boxNumber="boxNumber"
      :state="state"
      @closemodalVisible="closemodalVisible"
    />
  </div>
</template>

<script>
import keyboard from '@/components/keyboard.vue'
import course from '@/components/course.vue'
import tips from '@/components/tips.vue'

export default {
  name: 'Header',
  components: { keyboard, course, tips },
  data() {
    return {
      modal1Visible: false,
      color: '#00a6fa',
      text: '领取',
      count: 1,
      startTime: null,
      lastTime: null,
      text1: '',
      notes: true,
      isNotes: false,
      arkDetailData: {},
      valueNumber: null,
      state: 0,
      boxNumber: 0,
      time: 99999,
      countime: null,
      iskeyboard: true,
    }
  },
  mounted() {
    this.arkDetail()
    // this.timeDate()
  },
  methods: {
    closemodalVisible() {
      this.state = 0
    },
    clearValue() {
      this.$refs.keyboard.clearValue()
    },
    moveEvent() {
      clearInterval(this.countime)
      this.time = 60
      this.timeDate()
    },
    timeDate() {
      this.countime = setInterval(() => {
        this.time -= 1
        if (this.time < 1) {
          this.modal1Visible = false
          clearInterval(this.countime)
        }
      }, 1000)
    },
    keyboardInput(e) {
      this.valueNumber = e
    },
    close() {
      this.isNotes = false
    },
    // 右上角点击三次触发
    getSqlCheck() {
      if (this.count === 0) {
        this.startTime = new Date()
        this.lastTime = this.startTime
      } else if (this.count >= 1) {
        let nextTime = new Date()
        if (nextTime - this.startTime >= 3000) {
          // 超过五秒重置
          this.startTime = nextTime
          this.lastTime = nextTime
          this.count = 0
        } else {
          this.lastTime = nextTime
        }
        if (this.count === 2) {
          // 大于三次重置
          this.modal1Visible = true
          this.iskeyboard = true
          this.moveEvent()
          this.text = '登录'
          this.color = '#00a6fa'
          this.text1 = '请输入管理动态码'
          this.notes = false
          this.startTime = nextTime
          this.lastTime = nextTime
          this.count = 0
        }
      }
      this.count++
      console.log(this.count)
    },
    cancel() {
      this.modal1Visible = false
      this.isNotes = false
      this.iskeyboard = false
      this.state = 0
    },
    // 软键盘获取回来的事件
    trigger(e) {
      this.text = e
      console.log(e)
      if (e === '登录') {
        this.$get(
          `/app/xyj-ark/device/device-login?arkNumber=${this.$route.query.arkNumber}&loginCode=${this.valueNumber}`,
          {}
        ).then((res) => {
          if (res.code === 0) {
            this.modal1Visible = false
            sessionStorage.setItem('Authorization', res.loginVo.token)
            this.$router.push(
              `administration?arkNumber=${this.$route.query.arkNumber}`
            )
          } else {
            this.$message.warning(res.msg)
          }
        })
      }
      if (e == '领取') {
        this.$get(
          `/app/xyj-ark/device/receive-xyj?arkNumber=${this.$route.query.arkNumber}&code=${this.valueNumber}`
        ).then((res) => {
          if (res.code === 0) {
            let stauts = LockJsBridge.open(
              Number(res.boxInfo.boardNo),
              Number(res.boxInfo.lockNo)
            )
            if (stauts === 0 || stauts === 1) {
              this.modal1Visible = false
              this.iskeyboard = false
              this.state = 1
              this.boxNumber = res.boxInfo.boxNumber
              SystemJsBridge.playSound('sound_3')
            } else {
              this.$message.warning('开门超时，请联系管理员')
            }
          } else {
            this.$message.warning(res.msg)
          }
        })
      }

      if (e == '归还') {
        this.$get(
          `/app/xyj-ark/device/return-xyj?arkNumber=${this.$route.query.arkNumber}&code=${this.valueNumber}`
        ).then((res) => {
          if (res.code === 0) {
            let stauts = LockJsBridge.open(
              Number(res.boxInfo.boardNo),
              Number(res.boxInfo.lockNo)
            )
            if (stauts === 0 || stauts === 1) {
              this.modal1Visible = false
              this.iskeyboard = false
              this.state = 1
              this.boxNumber = res.boxInfo.boxNumber
              SystemJsBridge.playSound('sound_4')
            } else {
              this.$message.warning('开门超时，请联系管理员')
            }
          } else {
            this.$message.warning(res.msg)
          }
        })
      }
    },
    // 查询设置
    arkDetail() {
      this.$get(
        `/app/xyj-ark/xyj-ark-detail?arkNumber=${this.$route.query.arkNumber}`
      ).then((data) => {
        if (data.code == 0) {
          this.arkDetailData = data.arkInfo
        }
      })
    },
    // 传入更换值
    setModal1Visible(modal1Visible, num) {
      this.iskeyboard = true
      if (num == 1) {
        this.$get(
          `/app/xyj-ark/device/can-receive?arkNumber=${this.$route.query.arkNumber}`
        ).then((res) => {
          if (res.code === 0) {
            this.modal1Visible = modal1Visible
            this.moveEvent()
            this.text = '领取'
            this.color = '#ffa62e'
            this.text1 = '请输入领取码'
            SystemJsBridge.playSound('sound_1')
          } else {
            this.$message.warning(res.msg)
          }
        })
      }
      if (num == 2) {
        this.$get(
          `/app/xyj-ark/device/can-return?arkNumber=${this.$route.query.arkNumber}`
        ).then((res) => {
          if (res.code === 0) {
            this.modal1Visible = modal1Visible
            this.moveEvent()
            this.text = '归还'
            this.color = '#00a6fa'
            this.text1 = '请输入归还码'
            SystemJsBridge.playSound('sound_2')
          } else {
            this.$message.warning(res.msg)
          }
        })
      }
      this.notes = true

      this.isNotes = false
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .ant-modal-close-x {
  font-size: 30px !important;
  margin-right: 10px;
  margin-top: 10px;
}
.model-title {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  .model-flex {
    display: flex;
    font-size: 32px;
    font-weight: 700;
    align-items: baseline;
    color: #ffa62e;
    .text {
      font-size: 24px;
      // color: #00a6fa;
      margin-left: 16px;
    }
  }
}
.header {
  height:100%;
  box-sizing: border-box;
  background: -webkit-linear-gradient(#0c0b19, #326080); /* Safari 5.1 - 6.0 */

  background: -o-linear-gradient(#0c0b19, #326080); /* Opera 11.1 - 12.0 */

  background: -moz-linear-gradient(#0c0b19, #326080); /* Firefox 3.6 - 15 */

  background: linear-gradient(
    90deg,
    #0c0b19,
    #326080
  ); /* 标准的语法（必须放在最后） */
  padding: 30px 30px;
  .flex {
    display: flex;
    justify-content: space-between;
    .logo {
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
        margin-right: 3%;
      }
      .text {
        .title {
          font-size: 32px;
        }
        .sub {
          font-size: 22px;
          margin-top: 4px;
        }
        color: #fff;
      }
    }
    .set-up {
      width: 100px;
      height: 100px;
      // background: #fff;
    }
  }
  .box {
    display: flex;
    // height: calc(100% - 118px);
    padding-top: 10%;
    text-align: center;
    .card {
      width: 48%;
      background: #ffa62e;
      // height: 70%;
      display: inline-block;
      border-radius: 5%;
      padding: 7%;
      font-size: 32px;
      color: #fff;
      text-align: center;
      .icon {
        display: flex;
        align-items: center;
        .text {
          margin-left: 8%;
          font-size: 64px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
