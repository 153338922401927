<template>
  <div class="item-swiper">
    <swiper :options="swiperOption" style="width: 100%; height: 100%;">
      <swiper-slide v-for="(item, index) in banner" :key="index" style="width: 100%; height: 100%;">
        <img style="width: 100%; height: 100%;" :src="item" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  name: 'banner',
  components: {},
  data() {
    return {
      banner: [
        require('@/assets/banner/1.jpg'),
        require('@/assets/banner/2.jpg'),
        require('@/assets/banner/3.jpg'),
        require('@/assets/banner/4.jpg'),
      ],
      swiperOption: {
        loop: true, //是否循环轮播
        speed: 3000, //切换速度
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper\
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
          delay: 3000,
        },
      },
    }
  },
  mounted() {},
  methods: {},
}
</script>
<style lang="less" scoped>
.item-swiper {
  height: 100%;
}
</style>
