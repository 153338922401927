<template>
  <div class="content">
    <navHeader style="height: 42%;"/>
    <banner style="height: 58%;"/>
  </div>
</template>

<script>
import banner from '@/views/components/banner.vue'
import navHeader from '@/views/components/header.vue'
export default {
  name: 'Home',
  components: { banner, navHeader },
  data() {
    return {}
  },
  mounted() {
    // this.get()
  },
  methods: {},
}
</script>
<style lang="less" scoped>
.content {
  // height: 1920px;
  // width: 1080px;
  height: 100vh;
  width: 100vw;
}
</style>
